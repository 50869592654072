import './scripts/common';
import './scripts/common-nav';

import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init();

import * as playVideo from './scripts/play-video';
playVideo.init();

import * as hoverImages from './scripts/hover-images';
hoverImages.init();

import * as tabAccordionVisibility from './scripts/tab-accordion-visibility';
tabAccordionVisibility.init();

import Tab from 'bootstrap/js/dist/tab';
onFind('[data-toggle="tab"]',function (element) {
    new Tab(element);
});

import * as parallax from './scripts/parallax';
parallax.initInScope();

import * as slideOnScroll from './scripts/slide-on-scroll';
slideOnScroll.initInScope();


import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init();

import * as dropdownPersist from './scripts/dropdown-persist';
dropdownPersist.init();

import * as multiSelectDropdown from './scripts/multiselect-dropdown';
multiSelectDropdown.init();

import * as smoothScroll from '@elements/smooth-scroll';
smoothScroll.init({scrollOffset: 30, initialPosition: true});

import * as overlayToggle from './scripts/overlay-toggle';
overlayToggle.init();

import * as numberSpinner from '@elements/number-spinner';
numberSpinner.init();

import * as ajaxModal from './scripts/ajax-modal';
ajaxModal.init();

import * as countCartItems from './scripts/count-cart-items';
countCartItems.init();

import * as wishlist from './scripts/wishlist';
wishlist.init();

import {createApp} from 'vue';
import tooltipDirective from "./scripts/checkout/directives/tooltip/";

onFind('.vue-initialize-module', function(element) {

    let componentModule = element.dataset.initializeModule || false;
    if(componentModule) {
        let vueApp = createApp({});
        const getModule = () => import(`./scripts/checkout/templates/${componentModule}.vue`);
        getModule()
            .then(function(moduleName) {
                vueApp.component(componentModule, moduleName.default);
                console.log('vueApp', vueApp);
                tooltipDirective(vueApp);
                vueApp.mount(element);
            }).catch(function(evt) {
            console.error(evt);
        })
    }
});

import * as actionChanger from "@elements/action-changer";
actionChanger.init();

import * as openCartSidebar from "./scripts/open-cart-sidebar";
openCartSidebar.init();

import * as popperJS from "./scripts/libs/popper";
popperJS.init();

import * as initPopovers from "./scripts/init-popovers";
initPopovers.init();

import * as clipboard from './scripts/clipboard';
clipboard.init();

import * as productSuccessModal from './scripts/product-success-modal';
productSuccessModal.init();

import * as ajaxFormScrollTop from './scripts/ajax-form-scroll-top';
ajaxFormScrollTop.init();